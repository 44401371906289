button {
    margin: 5px;
}
.custom-btn {
    width: 120px;
    height: 20px;
    color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
    7px 7px 20px 0px rgba(0,0,0,.1),
    4px 4px 5px 0px rgba(0,0,0,.1);
    outline: none;
}
/* 1 */
.btn-1 {
    background: rgb(6,14,131);
    background: linear-gradient(0deg, rgba(6,14,131,1) 0%, rgba(12,25,180,1) 100%);
    border: none;
}
.btn-1:hover {
    background: rgb(0,3,255);
    background: linear-gradient(0deg, rgba(0,3,255,1) 0%, rgba(2,126,251,1) 100%);
}
/* 3 */
.btn-3 {
    background: rgb(0,172,238);
    background: linear-gradient(0deg, rgba(0,172,238,1) 0%, rgba(2,126,251,1) 100%);
    width: 130px;
    height: 40px;
    line-height: 42px;
    padding: 0;
    border: none;

}
.btn-3 span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}
.btn-3:before,
.btn-3:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    background: rgba(2,126,251,1);
    transition: all 0.3s ease;
}
.btn-3:before {
    height: 0%;
    width: 2px;
}
.btn-3:after {
    width: 0%;
    height: 2px;
}
.btn-3:hover{
    background: transparent;
    box-shadow: none;
}
.btn-3:hover:before {
    height: 100%;
}
.btn-3:hover:after {
    width: 100%;
}
.btn-3 span:hover{
    color: rgba(2,126,251,1);
}
.btn-3 span:before,
.btn-3 span:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    background: rgba(2,126,251,1);
    transition: all 0.3s ease;
}
.btn-3 span:before {
    width: 2px;
    height: 0%;
}
.btn-3 span:after {
    width: 0%;
    height: 2px;
}
.btn-3 span:hover:before {
    height: 100%;
}
.btn-3 span:hover:after {
    width: 100%;
}